import React from "react";

class PaymentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_print: "",
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.shipping_status !== this.props.shipping_status) {
            this.update_badge()
        }
    }

    update_badge() {
        var status_print = "";
        var status_css = "";
        if (typeof this.props.company_id == "undefined" || (typeof this.props.company_id != "undefined" && (this.props.company_id == 1 || this.props.company_id == 7 || this.props.company_id == 15 || this.props.company_id == 16))) {
            if (typeof this.props.void_date != "undefined" && this.props.void_date != null) {
                var status_print = "VOID";
                var status_css = "danger";
            } else if (this.props.shipping_status == 0) {
                var status_print = "Processing";
                var status_css = "pink";
            } else if (this.props.shipping_status == 1) {
                var status_print = "Scheduled";
                var status_css = "purple";
            } else if (this.props.shipping_status == 2) {
                var status_print = "Out for Delivery";
                var status_css = "info";
            } else if (this.props.shipping_status == 3) {
                var status_print = "Delivered";
                var status_css = "success";
            } else if (this.props.shipping_status == 9) {
                var status_print = "Held - Pending Approval";
                var status_css = "dark";
            } else if (this.props.shipping_status === null) {
                var status_print = "Unknown/Error";
                var status_css = "danger";
            }
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css}>{this.state.status_print}</div>
        );
    }
}

export default PaymentStatus;