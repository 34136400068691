import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getStaff} from '../Staff/StaffFunctions.js';
import {getAccounts, getDeliveryDate} from "../Accounts/AccountFunctions";

moment.tz.setDefault("America/Toronto");

var money = require("money-math");

class SalesNSLC extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.goToAccount = this.goToAccount.bind(this);

        this.state = {
            accounts: [],
            loading: true,
            avg_order_duration: 0,
            since_last_order: 0,
            num_orders: 0,
            total_spent: 0,
            staff: [],
            dir: 1,

            startDate: (localStorage.getItem('acc_startDate') != null ? moment(localStorage.getItem('acc_startDate')) : moment().subtract(3, 'days'), moment().endOf('day')),
            endDate: (localStorage.getItem('acc_endDate') != null ? moment(localStorage.getItem('acc_endDate')) : moment().subtract(2, 'days'), moment().endOf('day')),
            account_status: (localStorage.getItem('account_status') != null ? localStorage.getItem('account_status') : null),
            account_status_name: (localStorage.getItem('account_status_name') != null ? localStorage.getItem('account_status_name') : "All"),
            account_rep: (localStorage.getItem('account_rep') != null ? localStorage.getItem('account_rep') : 0),
            account_class: (localStorage.getItem('account_class') != null ? localStorage.getItem('account_class') : null),
            account_rep_name: (localStorage.getItem('account_rep_name') != null ? localStorage.getItem('account_rep_name') : 0),
            account_statuses: {0: "Closed", 1: "Active"},
            account_class_name: (localStorage.getItem('store_class_name') != null ? localStorage.getItem('store_class_name') : "All"),
            account_classes: {"AA": "AA", "A": "A", "B": "B", "C": "C", "D": "D"},
            realfake_types: {1: "Real", 0: "Fake"},
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
        };
    }

    componentDidMount() {
        document.title = "NSLC Sales Report | Bevvy";
        var self = this;
        getStaff(function (staff) {
            staff[0] = {
                'first_name': 'All',
                'last_name': ''
            };
            staff['null'] = {
                'first_name': '(Unassigned)',
                'last_name': ''
            };
            self.setState({
                staff: staff
            }, () => {
                self.getAccounts()
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('acc_startDate', picker.startDate);
            localStorage.setItem('acc_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.getAccounts()
            });
        }
    }

    changeAccountStatus = (account_status) => {
        if (account_status == null) {
            var account_status_name = "All"
        } else {
            var account_status_name = this.state.account_statuses[account_status]
        }
        localStorage.setItem('account_status', account_status);
        localStorage.setItem('account_status_name', account_status_name);
        this.setState({
            account_status: account_status,
            account_status_name: account_status_name
        }, () => {
            this.getAccounts()
        });
    };

    changeRep = (sales_rep_id) => {
        var account_rep_name = this.state.staff[sales_rep_id].first_name + " " + this.state.staff[sales_rep_id].last_name;
        localStorage.setItem('account_rep', sales_rep_id);
        localStorage.setItem('account_rep_name', account_rep_name);
        this.setState({
            account_rep: sales_rep_id,
            account_rep_name: account_rep_name
        }, () => {
            this.getAccounts()
        });
    };

    changeClass = (account_class) => {
        var account_class_name = "All"
        if (account_class != null) {
            account_class_name = account_class;
        }
        localStorage.setItem('account_class', account_class);
        localStorage.setItem('account_class_name', account_class_name);
        this.setState({
            account_class: account_class,
            account_class_name: account_class_name
        }, () => {
            this.getAccounts()
        });
    };

    goToAccount = (account_id) => {
        this.props.history.push("/account/" + account_id);
    };

    getAccounts() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/inventory/nslc/?account_status=' + self.state.account_status + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&sales_rep_id=" + self.state.account_rep + "&account_class=" + self.state.account_class)
            .then(function (response) {
                var num_accounts = 0;
                response.data.map(function (account, key) {
                    num_accounts++;
                });
                self.setState({
                    accounts: response.data,
                    loading: false,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        accounts: [],
                        loading: false
                    });
                }
            });
    }

    onSort(event, sortKey) {
        var dir = this.state.dir;

        function compare(a, b) {
            if (a[sortKey] > b[sortKey]) {
                return -1 * dir;
            }
            if (a[sortKey] < b[sortKey]) {
                return 1 * dir;
            }
            return 0;
        }

        this.setState({
            accounts: this.state.accounts.sort(compare),
            dir: -1 * dir
        });
    }

    onSortData(event, product_id, sortKey) {
        var dir = this.state.dir;

        function compare(a, b) {
            if (typeof a['data'][product_id] != "undefined") {
                if (typeof b['data'][product_id] == "undefined" || a['data'][product_id][sortKey] > b['data'][product_id][sortKey]) {
                    return -1 * dir;
                }
                if (typeof b['data'][product_id] != "undefined" && a['data'][product_id][sortKey] < b['data'][product_id][sortKey]) {
                    return 1 * dir;
                }
                return 0;
            } else {
                return 1 * dir;
            }
        }

        this.setState({
            accounts: this.state.accounts.sort(compare),
            dir: -1 * dir
        });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var total = {
            16: {
                "sold": 0,
                "cases_in": 0,
                "end": 0
            },
            65: {
                "sold": 0,
                "cases_in": 0,
                "end": 0
            }
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>NSLC Sales Report</div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.account_status) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Status: <b>{this.state.account_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeAccountStatus(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.account_statuses).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeAccountStatus(key)}>{this.state.account_statuses[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup direction="down">
                        <UncontrolledButtonDropdown direction="down">
                            <DropdownToggle direction="down" color={(String(this.state.account_rep) == "0" ? "primary" : "warning")} caret
                                            style={{fontSize: '13px', width: 'auto'}}>
                                Rep: <b>{this.state.account_rep_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeRep(0)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.staff).map((sales_rep_id, i) => {
                                    if (this.state.staff[sales_rep_id].status == 1) {
                                        return (<DropdownItem key={sales_rep_id}
                                                              onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>)
                                    }
                                }, this)}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color={(String(this.state.account_class) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                <b>Class: {this.state.account_class_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeClass(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.account_classes).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeClass(key)}>{key}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title mb-0">{this.state.accounts.length} NSLC Stores</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.accounts.length > 0 ? "table table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th colSpan={3}></th>
                                        <th className="text-center border-left h4" colSpan={3}>BEE'S KNEES 4pk</th>
                                        <th className="text-center border-left h4" colSpan={3}>SANDBAGGER 6pk</th>
                                    </tr>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'license_num')}><i class="fas fa-sort"></i> Name</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'name')}><i class="fas fa-sort"></i> Address</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'city')}><i class="fas fa-sort"></i> City</th>
                                        <th className="border-left" onClick={e => this.onSortData(e, 16, 'sold')}><i class="fas fa-sort"></i> Sales</th>
                                        <th onClick={e => this.onSortData(e, 16, 'cases_in')}><i class="fas fa-sort"></i> Cases In</th>
                                        <th onClick={e => this.onSortData(e, 16, 'end')}><i class="fas fa-sort"></i> Ending Inv.</th>

                                        <th className="border-left border-dark" onClick={e => this.onSortData(e, 65, 'sold')}><i class="fas fa-sort"></i> Sales</th>
                                        <th onClick={e => this.onSortData(e, 65, 'cases_in')}><i class="fas fa-sort"></i> Cases In</th>
                                        <th onClick={e => this.onSortData(e, 65, 'end')}><i class="fas fa-sort"></i> Ending Inv.</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.entries(this.state.accounts).map(([key, account]) => {
                                        if (typeof account['data'][16] != "undefined") {
                                            total[16].sold += account['data'][16].sold;
                                            total[16].cases_in += account['data'][16].cases_in;
                                            total[16].end += account['data'][16].end;
                                        }
                                        if (typeof account['data'][65] != "undefined") {
                                            total[65].sold += account['data'][65].sold;
                                            total[65].cases_in += account['data'][65].cases_in;
                                            total[65].end += account['data'][65].end;
                                        }
                                        return (
                                            <tr key={key} onClick={this.goToAccount.bind(this, account.account_id)}>
                                                <td className="text-left">{account.name}</td>
                                                <td className="text-left">{account.address1}</td>
                                                <td className="text-left">{account.city}</td>
                                                <td className="border-left">{(typeof account['data'][16] != "undefined" ? account['data'][16].sold : "")}</td>
                                                <td>{(typeof account['data'][16] != "undefined" ? account['data'][16].cases_in : "")}</td>
                                                <td className={(typeof account['data'][16] != "undefined" && account['data'][16].end < 7 ? "text-danger text-right" : "")}>{(typeof account['data'][16] != "undefined" ? account['data'][16].end : "")}</td>

                                                <td className="border-left">{(typeof account['data'][65] != "undefined" ? account['data'][65].sold : "")}</td>
                                                <td>{(typeof account['data'][65] != "undefined" ? account['data'][65].cases_in : "")}</td>
                                                <td className={(typeof account['data'][65] != "undefined" && account['data'][65].end < 7 ? "text-danger text-right" : "")}>{(typeof account['data'][65] != "undefined" ? account['data'][65].end : "")}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colSpan={3}>Total:</th>
                                        <th className="border-left">{total[16].sold.toLocaleString("en-US")}</th>
                                        <th>{total[16].cases_in.toLocaleString("en-US")}</th>
                                        <th>{total[16].end.toLocaleString("en-US")}</th>

                                        <th className="border-left">{total[65].sold.toLocaleString("en-US")}</th>
                                        <th>{total[65].cases_in.toLocaleString("en-US")}</th>
                                        <th>{total[65].end.toLocaleString("en-US")}</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (SalesNSLC);