import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {InputGroup, Input, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';

import {API_ROOT} from '../../api-config';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, {Draggable} from '@fullcalendar/interaction';

import moment from 'moment';
import 'moment-timezone';
import Swal from 'sweetalert2'
import {getStaff} from '../Staff/StaffFunctions.js';

axios.defaults.withCredentials = true;
moment.tz.setDefault("America/Toronto");

class CalendarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts: [],
            loading: true,
            avg_order_duration: 0,
            since_last_order: 0,
            num_orders: 0,
            total_spent: 0,
            city: "All",
            staff: [],
            cities: {},
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : 99),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "On-Premise & Retail"),
            account_rep: localStorage.getItem('staff_id'),
            account_rep_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            account_types: {
                0: "Special",
                1: "On-Premise",
                2: "TBS",
                3: "LCBO",
                4: "Individual",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                10: "Duty-Free",
                11: "Convenience",
                98: "Retail",
                99: "On-Premise & Retail"
            },
            calendarEvents: []
        };
        this.handleEventRecieve = this.handleEventRecieve.bind(this);
        this.updateNotes = this.updateNotes.bind(this);
    }

    componentDidMount() {
        document.title = "Call Calendar | Bevvy";
        var self = this;
        getStaff(function (staff) {
            staff[0] = {
                'first_name': 'All',
                'last_name': ''
            };
            staff['null'] = {
                'first_name': '(Unassigned)',
                'last_name': ''
            };
            self.setState({
                staff: staff
            }, () => {
                self.getAccounts()
            });
        });

        var draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event"
        });
    }

    changeAccountType = (account_type) => {
        if (account_type == null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type', account_type);
        localStorage.setItem('account_type_name', account_type_name);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        }, () => {
            this.getAccounts()
        });
    };

    changeRep = (sales_rep_id) => {
        var account_rep_name = this.state.staff[sales_rep_id].first_name + " " + this.state.staff[sales_rep_id].last_name;
        localStorage.setItem('account_rep', sales_rep_id);
        localStorage.setItem('account_rep_name', account_rep_name);
        this.setState({
            account_rep: sales_rep_id,
            account_rep_name: account_rep_name
        }, () => {
            this.getAccounts()
        });
    };

    changeCity = (city) => {
        this.setState({
            city: city,
        }, () => {
            this.getAccounts()
        });
    };

    getAccounts() {
        this.setState({
            loading: true
        });
        var self = this;
        var cities = {}
        var account_type = self.state.account_type;
        if (account_type == "null") {
            account_type = -4; // not individuals
        }
        axios.get(API_ROOT + '/accounts/summary/?account_realfake=1&account_type=' + account_type + '&account_status=1&sales_rep_id=' + self.state.account_rep)
            .then(function (response) {
                var num_accounts = 0;
                response.data.map(function (account, key) {
                    num_accounts++;
                    var city = account['city'].toString().toUpperCase().trim()
                    if (typeof cities[city] == "undefined") {
                        cities[city] = city;
                    }
                });

                cities = Object.keys(cities)
                    .sort()
                    .reduce(function (acc, key) {
                        acc[key] = cities[key];
                        return acc;
                    }, {});

                self.setState({
                    cities: cities,
                    accounts: response.data,
                    loading: false,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        accounts: [],
                        loading: false
                    });
                }
            });

        axios.get(API_ROOT + '/call_notes/summary?type=' + this.state.account_type + '&rep=' + this.state.account_rep + '&city=' + this.state.city)
            .then(function (response) {
                self.setState({
                    calendarEvents: response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        calendarEvents: [],
                        loading: false
                    });
                }
            });
    }

    onSort(event, sortKey) {
        function compare(a, b) {
            if (a[sortKey] > b[sortKey]) {
                return -1;
            }
            if (a[sortKey] < b[sortKey]) {
                return 1;
            }
            return 0;
        }

        this.setState({
            accounts: this.state.accounts.sort(compare)
        });
    }

    updateNotes = event => {
        console.log('hi')
    }

    eventClick = eventClick => {
        var self = this;
        var id = false;
        for (let x in this.state.calendarEvents) {
            if (this.state.calendarEvents[x].id == eventClick.event.id) {
                id = x;
            }
        }
        var data = this.state.calendarEvents;
        var event = this.state.calendarEvents[id];
        var html = '<br><p><b>' + moment(event.start).format("ddd MMM d H:mm A") + '</b> to <b>' + moment(event.end).format("ddd MMM d H:mm A") + '</b></p><p><b>Notes:</b><br>';
        if (event.editable) {
            html += '<textarea id="notes" class="form-control">' + event.notes + '</textarea>';
        } else {
            html += event.notes;
        }
        html += '</p>';
        Swal({
            title: eventClick.event.title,
            html: html,
            showCloseButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: 'red',
            confirmButtonText: 'Go to Call Note',
            cancelButtonText: 'Delete',
            showCancelButton: event.editable
        }).then((setupPayment) => {
            if (typeof setupPayment.dismiss == 'undefined') {
                self.props.history.push("/account/" + event.account_id + '/call/' + event.id);
            } else if (setupPayment.dismiss == "cancel") {
                axios.delete(API_ROOT + '/account/' + event.account_id + "/call_notes/" + eventClick.event.id)
                    .then(function (response) {
                        eventClick.event.remove();
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                calendarEvents: [],
                                loading: false
                            });
                        }
                    });
            }
            if (event.editable) {
                event['notes'] = window.document.getElementById("notes").value;
                event['start'] = moment.utc(event.start).format("YYYY-MM-DD HH:mm:ss");
                event['end'] = moment.utc(event.end).format("YYYY-MM-DD HH:mm:ss");
                data[id] = event;
                axios.put(API_ROOT + '/account/' + event.account_id + "/call_notes/" + event.id, event)
                    .then(function (response) {
                        self.setState({
                            calendarEvents: data
                        });
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            self.props.userSignOut()
                        } else {
                            Swal("Error", error.response.data.Message, "error");
                            self.setState({
                                calendarEvents: [],
                                loading: false
                            });
                        }
                    });
            }
        });
    };

    obj_search(arr, s) {
        var matches = [], i, key;
        for (i = arr.length; i--;)
            for (key in arr[i])
                if (arr[i].hasOwnProperty(key) && arr[i][key].indexOf(s) > -1)
                    matches.push(arr[i]);  // <-- This can be changed to anything
        return matches;
    };

    handleEventDrop = eventDropInfo => {
        var self = this;
        var id = false;
        for (let x in this.state.calendarEvents) {
            if (this.state.calendarEvents[x].id == eventDropInfo.event.id) {
                id = x;
            }
        }
        var data = this.state.calendarEvents;
        var event = this.state.calendarEvents[id];
        event['start'] = moment.utc(eventDropInfo.event.start).format("YYYY-MM-DD HH:mm:ss");
        event['end'] = moment.utc(eventDropInfo.event.end).format("YYYY-MM-DD HH:mm:ss");
        data[id] = event;
        axios.put(API_ROOT + '/account/' + event.account_id + "/call_notes/" + event.id, event)
            .then(function (response) {
                self.setState({
                    calendarEvents: data
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        calendarEvents: [],
                        loading: false
                    });
                }
            });
    };

    handleEventRecieve = info => {
        console.log('receive')
        var self = this;
        var end = new Date(moment(info.date).add(30, "minute").valueOf())
        var data = {
            notes: "",
            staff_id: localStorage.getItem('staff_id'),
            start: moment.utc(info.date).format("YYYY-MM-DD HH:mm:ss"),
            end: moment.utc(end).format("YYYY-MM-DD HH:mm:ss"),
            editable: 1,
            account_id: info.draggedEl.getAttribute("url")
        }
        axios.post(API_ROOT + '/account/' + info.draggedEl.getAttribute("url") + '/call_notes', data)
            .then(function (response) {
                const newEvent = {
                    title: info.draggedEl.getAttribute("title"),
                    start: info.date,
                    end: end,
                    id: response.data.call_id,
                    notes: "",
                    editable: 1,
                    account_id: info.draggedEl.getAttribute("url")
                };
                self.setState({
                    calendarEvents: self.state.calendarEvents.concat(newEvent)
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        calendarEvents: [],
                        loading: false
                    });
                }
            });
        return;
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Call Calendar</div>
                    <div className="ml-auto">
                        <InputGroup direction="down">
                            <UncontrolledButtonDropdown direction="down">
                                <DropdownToggle direction="down" color="primary" caret
                                                style={{fontSize: '13px', width: 'auto'}}>
                                    <b>{this.state.account_rep_name}</b>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem key="null" onClick={() => this.changeRep(0)}>All</DropdownItem>
                                    <DropdownItem divider/>
                                    {Object.keys(this.state.staff).map((sales_rep_id, i) => {
                                        if (this.state.staff[sales_rep_id].status == 1) {
                                            return (<DropdownItem key={sales_rep_id}
                                                                  onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>)
                                        }
                                    }, this)}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </InputGroup>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color={(String(this.state.account_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                Type: <b>{this.state.account_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.account_types).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup direction="down">
                        <UncontrolledButtonDropdown direction="down">
                            <DropdownToggle direction="down" color={(String(this.state.cities) == "0" ? "primary" : "warning")} caret
                                            style={{fontSize: '13px', width: 'auto'}}>
                                City: <b>{this.state.city}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeCity("All")}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.cities).map((city, i) =>
                                    <DropdownItem key={city}
                                                  onClick={() => this.changeCity(city)}>{city}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <div className="container p-2 pr-0">
                                <div style={{maxHeight: "800px", minHeight: "50px", overflowY: "auto"}} id="external-events">
                                    <div className={(this.state.accounts.length > 0) ? "d-none" : "alert alert-danger"}>
                                        There are no accounts assigned to that individual
                                    </div>
                                    <div className={(this.state.accounts.length > 0) ? "row font-weight-bold d-none d-md-flex" : "d-none"}>
                                        <div className="col-5" onClick={e => this.onSort(e, 'name')}><i className="fas fa-sort"></i> Name</div>
                                        <div className="col-4" onClick={e => this.onSort(e, 'city')}><i className="fas fa-sort"></i> City</div>
                                        <div className="col-3" onClick={e => this.onSort(e, 'last_visited')}><i className="fas fa-sort"></i> Last Visit</div>
                                    </div>
                                    {Object.entries(this.state.accounts).map(([key, account]) => {
                                        var freq_style = "";
                                        if (account.last_visited == null) {
                                            if (account.call_frequency > 0) {
                                                freq_style = "danger";
                                            }
                                        } else {
                                            freq_style = "success";
                                            if (account.last_visited > account.call_frequency) {
                                                freq_style = "warning";
                                                if (account.last_visited > (account.call_frequency * 1.5)) {
                                                    freq_style = "danger";
                                                }
                                            }
                                        }
                                        if (this.state.city == account.city.trim() || this.state.city == "All") {
                                            return (
                                                <div role="button" className="fc-event bg-light row rounded mb-1 p-1" url={account.account_id} title={account.name} key={key}>
                                                    <div className="col col-12 col-lg-5">{account.name}</div>
                                                    <div className="col col-12 col-lg-4">{account.city}</div>
                                                    <div className="col col-12 col-lg-3">
                                                        <div
                                                            className={"font-weight-normal badge badge-" + freq_style}>{(account.last_visited != null ? account.last_visited + " days" : "Not Visited")}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-default col">
                        <FullCalendar
                            initialView="timeGridWeek"
                            headerToolbar={{
                                left: "prev,next",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay"
                            }}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            rerenderDelay={10}
                            eventDurationEditable={true}
                            editable={true}
                            droppable={false}
                            eventDrop={this.handleEventDrop}
                            eventResize={this.handleEventDrop}
                            drop={this.handleEventRecieve}
                            ref={this.calendarComponentRef}
                            events={this.state.calendarEvents}
                            eventReceive={this.eventReceive}
                            eventClick={this.eventClick}
                            nowIndicator={true}
                            slotMinTime={"07:00:00"}
                            slotDuration={'00:15:00'}
                            slotLabelInterval={'01:00'}
                            defaultTimedEventDuration={'00:30'}
                            height={"790px"}
                            allDaySlot={false}
                        />
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (CalendarPage);