import React from "react";

class PaymentStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            status_print: "",
            status_css: ""
        };
    }
    componentDidMount() {
        this.update_badge()
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.is_paid !== this.props.is_paid) {
            this.update_badge()
        }
    }

    update_badge(){
        var status_print = "";
        var status_css = "";
        if (typeof this.props.company_id == "undefined" || (typeof this.props.company_id != "undefined" && (this.props.company_id == 1 || this.props.company_id == 7))) {
            if (typeof this.props.void_date != "undefined" && this.props.void_date != null) {
                var status_print = "VOID";
                var status_css = "danger";
            } else if (this.props.is_paid == 1) {
                var status_print = "Paid";
                var status_css = "success";
            } else if (this.props.is_paid == 0) {
                var status_print = "Not Paid";
                var status_css = "warning";
            } else if (this.props.is_paid == 5) {
                var status_print = "Promo";
                var status_css = "info";
            } else if (this.props.is_paid == 4) {
                var status_print = "Bad Debt";
                var status_css = "dark";
            } else if (this.props.is_paid === null) {
                var status_print = "Declined/Error";
                var status_css = "danger";
            }
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }
    render() {
        return (
            <div className={this.state.status_css}>{this.state.status_print}</div>
        );
    }
}
export default PaymentStatus;