/*!
 *
 * Angle - Bootstrap Admin App + ReactJS
 *
 * Version: 3.8.9.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { API_ROOT } from './api-config';
import Geolocation from '@react-native-community/geolocation';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(o => o.async = true);


class App extends Component {
    //login
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            first_name:'',
            last_name:'',
            role_id: ''
        };
        this.refresh_from_local = this.refresh_from_local.bind(this)
    }

    async componentDidMount() {
        this.userHasAuthenticated(false);
        var self = this;
        self.refresh_from_local(); // trigger immediate reload from local storage
        if (localStorage.getItem('first_name')!==null) {
            axios.defaults.withCredentials = true;
            axios.post(`${API_ROOT}/heartbeat`, { withCredentials: true})
                .then(function (response) {
                    self.userHasAuthenticated(true);
                    self.setState({ isAuthenticating: false });
                    self.refresh_from_local()
                    self.report_location();
                })
                .catch(function (error) {
                    self.userSignOut()
                    self.setState({ isAuthenticating: false });
                });
        } else {
            self.userSignOut();
            this.setState({ isAuthenticating: false });
        }

        // send current location
        setInterval(async () => {
            this.report_location();
        }, 60 * 1000);
    }

    report_location(){
        if (this.state.isAuthenticated==true) {
            Geolocation.getCurrentPosition(
                (position) => {
                    var coords = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        speed: position.coords.speed,
                        accuracy: position.coords.accuracy,
                    };
                    axios.defaults.withCredentials = true;
                    axios.post(`${API_ROOT}/ping`, {coords})
                        .catch(function (error) {});
                },
                (error) => {
                    // See error code charts below.
                    console.log(error.code, error.message);
                },
                {enableHighAccuracy: true, timeout: 15000, maximumAge: 10000}
            );
        }
    }

    refresh_from_local() {
        this.setState({
            first_name: localStorage.getItem('first_name'),
            last_name: localStorage.getItem('last_name'),
            role_id: localStorage.getItem('role_id'),
        });
    }

    userHasAuthenticated = authenticated => {
        this.setState({ isAuthenticated: authenticated });
    }
    userSignOut = event => {
        axios.defaults.withCredentials = true;
        axios.post(`${API_ROOT}/logout`, { withCredentials: true})
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('role_id');
        this.userHasAuthenticated(false);
    }

    render() {

        // specify base href from env varible 'WP_BASE_HREF'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global WP_BASE_HREF */
        const basename = '/';

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            userSignOut: this.userSignOut,
            first_name:this.state.first_name,
            last_name:this.state.last_name,
            role_id: this.state.role_id,
            refresh_from_local: this.refresh_from_local,
        };


        return (
            !this.state.isAuthenticating &&
            <BrowserRouter basename={basename}>
            <Routes childProps={childProps} />
            </BrowserRouter>
        );
    }
}

export default App;
