import React, { Component } from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Alert, Table, FormGroup, ButtonGroup, Button, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import DeliveryStatus from '../Orders/DeliveryStatus';
moment.tz.setDefault("America/Toronto");

class BOLDetails extends Component {
    constructor(props){
        super(props);

        this.updateDate = this.updateDate.bind(this);
        this.updateEstDate = this.updateEstDate.bind(this);
        this.state = {
            bol_id: null,
            items: [],
            downloading: false,
            loading: true,
            po: "",
            inventory_source_id: 0,
            inventory_sources: [],
            notes: undefined,
            select_inventory_source: null,
            select_delivery_status: null,
            select_delivery_date_status: null,
            delivery_statuses: {0: "Processing", 1: "Scheduled", 2: "Out for Delivery", 3: "Delivered", 9: "Held - Pending Approval"},
            est_delivery_date: null,
            delivery_date: null,
            reference_id: null,
        };
    }
    componentDidMount(){
        document.title = "BOL # " + this.props.match.params.bol_id + " | Bevvy";
        this.setState({bol_id: this.props.match.params.bol_id})
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/bol/' + this.props.match.params.bol_id)
            .then(function (response) {
                if (response.data.est_delivery_date != null) {
                    response.data.est_delivery_date = moment(response.data.est_delivery_date, 'MMM. D, YYYY');
                }
                if (response.data.delivery_date != null) {
                    response.data.delivery_date = moment(response.data.delivery_date);
                }
                self.setState(response.data, () => {
                    self.setState({loading: false})
                });
                getInventorySources('can_deliver',function(inventory_sources){
                    inventory_sources[0] = {
                        'name': '---',
                    };
                    self.setState({
                        inventory_sources:inventory_sources,
                        inventory_source_name: inventory_sources[response.data.inventory_source_id].name,
                    });
                });
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    // BOL Notes
    noteTyping = (event) => {
        this.setState({notes: event.target.value});
    };
    updateNotes = (event) => {
        this.saveBOL({notes:this.state.notes});
    };

    // Changing inventory source
    editInventorySource = () => {
        this.setState({
            dropdown_inventory_source_print: this.state.inventory_sources[this.state.inventory_source_id].name,
            select_inventory_source: this.state.inventory_source_id
        })
    };
    changeInventorySource = (inventory_source_id) => {
        var notes = this.state.notes
        var params = {
            inventory_source_id: inventory_source_id,
            select_inventory_source: null,
            inventory_source_name: this.state.inventory_sources[inventory_source_id].name,
            notes:notes
        };
        this.setState(params);
        this.saveBOL(params);
    };

    // Changing delivery status
    editDeliveryStatus = () => {
        this.setState({
            dropdown_delivery_status_print: this.state.delivery_statuses[this.state.shipping_status],
            select_delivery_status: this.state.shipping_status
        })
    };
    changeDeliveryStatus = (shipping_status) => {
        var params = {
            shipping_status: shipping_status,
            select_delivery_status: null
        };
        this.setState(params);
        this.saveBOL(params);
    }

    // On update Est Delivery Date
    updateEstDate(event, picker) {
        event.preventDefault();
        if (event.type=="apply") {
            this.setState({
                est_delivery_date: picker.startDate,
            });
            this.saveBOL({est_delivery_date: picker.startDate})
        }
    }

    // On update Delivery Date
    updateDate(event, picker) {
        event.preventDefault();
        if (event.type=="apply") {
            this.setState({
                select_delivery_date_status:null,
                delivery_date: picker.startDate,
            });
            this.saveBOL({delivery_date: picker.startDate})
        }
    }
    editDeliveryDate = () => {
        this.setState({
            select_delivery_date_status:true
        })
    };

    markDeliveryToday = (event) => {
        event.preventDefault();
        var params = {
            shipping_status: 2,
            select_delivery_status: null,
            select_delivery_date_status:null,
            est_delivery_date: moment()
        };
        this.setState(params);
        this.saveBOL(params);
    };

    // Save any details
    saveBOL(params) {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT+'/bol/'+this.props.match.params.bol_id, params)
        .then(function () {
            toast.success("Changes Saved", {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
        .catch(function (error) {
            if (error.response.status===401){
                self.props.userSignOut()
            } else {
                Swal("Error", error.response.data.Message, "error");
            }
        });
    }

    getPDF = function() {
        this.setState({downloading:true});
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT+'/bol/'+this.state.bol_id+"/download")
            .then(function (response) {
                var element = document.createElement('a');
                element.setAttribute('href', response.data.url);
                if ("download" in document.createElement("a")) {
                    element.setAttribute('download', 'Storyteller BOL #' + self.state.bol_id + '.pdf');
                }
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                self.setState({downloading:false});
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
                self.setState({downloading:false});
            });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../bols">Bill of Ladings</Link>&nbsp;/&nbsp;BOL #{this.state.bol_id}
                    <div className="ml-auto">
                        <a className={(this.state.bol_id >= 1234 && this.state.account_type == 2 ? "btn btn-info mr-1" : "d-none")} target="_blank"
                           href={"/tbs_po/" + this.state.po + ".pdf"}>
                            <i className="fas fa-paperclip"></i> TBS PO
                        </a>
                        <Button color="info" onClick={this.getPDF.bind(this)} disabled={this.state.downloading}>
                            <i className={(this.state.downloading ? "fa fa-spinner fa-spin" : "fa fa-download")}></i> Printable Receipt
                        </Button>
                    </div>
                </div>
                <Card className={(this.state.loading?"card card-default whirl traditional":"card card-default")}>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal">
                                    <FormGroup row>
                                        <Col md="4" sm="5">Account:</Col>
                                        <Col md="8" sm="7">
                                            <strong><Link to={"../account/"+this.state.account_id}>{this.state.name}</Link></strong>
                                            <div>{this.state.address1}, {this.state.city}, {this.state.province}</div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Date/Time:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.date_ordered}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Placed By:</Col>
                                        <Col md="8" sm="7">
                                            <strong>{this.state.first_name} {this.state.last_name}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">PO #:</Col>
                                        <Col md="8" sm="7" className="font-weight-bold">
                                            <strong>{this.state.po}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Delivery Instructions:</Col>
                                        <Col md="8" sm="7" className="font-weight-bold">
                                            <strong>{this.state.delivery_instructions}</strong>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Delivery Status:</Col>
                                        <Col md="8" sm="7">
                                            <span className={(this.state.shipping_status<=1?"":"d-none")}>
                                                <button className="btn btn-sm btn-primary float-right" onClick={this.markDeliveryToday}><i className="fa fa-shipping-fast"></i> Mark as Delivery Today</button>
                                            </span>
                                            <div className={(this.state.select_delivery_status!=null?"d-none":"")}>
                                                <DeliveryStatus shipping_status={this.state.shipping_status} />
                                                <Button size="xs" color="default" className="ml-1 far fa-edit" onClick={this.editDeliveryStatus}></Button>
                                            </div>
                                            <UncontrolledButtonDropdown className={(this.state.select_delivery_status==null?"d-none":"")}>
                                                <DropdownToggle color="secondary" caret size="sm">
                                                    {this.state.dropdown_delivery_status_print}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.delivery_statuses).map(function(delivery_status, key) {
                                                        return (
                                                            <DropdownItem key={delivery_status} onClick={() => this.changeDeliveryStatus(delivery_status)}>{this.state.delivery_statuses[delivery_status]}</DropdownItem>
                                                        )
                                                    },this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </Col>
                                    </FormGroup>
                                    <div>
                                        <FormGroup row>
                                            <Col md="4" sm="5">Est. Delivery Date:</Col>
                                            <Col md="8" sm="7">
                                                <span className={(this.state.shipping_status!=3?"":"d-none")}>
                                                    <DateRangePicker
                                                        singleDatePicker={true}
                                                        date={(this.state.est_delivery_date==null?moment():this.state.est_delivery_date)}
                                                        onEvent={this.updateEstDate}
                                                        autoUpdateInput={true}
                                                        minDate={moment()}
                                                    >
                                                        <div className="btn btn-secondary">
                                                            {(this.state.est_delivery_date==null?'- Not Selected - ':this.state.est_delivery_date.format('MMM. D, YYYY'))}
                                                            <span className="fas fa-caret-down fa-sm"></span>
                                                        </div>
                                                    </DateRangePicker>
                                                </span>
                                                <span className={(this.state.shipping_status==3?"text-bold":"d-none")}>
                                                     {(this.state.est_delivery_date==null?'- Not Selected - ':this.state.est_delivery_date.format('MMM. D, YYYY'))}
                                                </span>
                                                <span className={((this.state.shipping_status>=2 && this.state.est_delivery_date==null)?"text-danger text-bold ml-2":"d-none")}><i className="fas fa-exclamation-triangle fa-lg"></i> Please Select Est. Delivery Date</span>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <div className={(this.state.shipping_status==3?"":"d-none")}>
                                        <FormGroup row>
                                            <Col md="4" sm="5">Delivery Date:</Col>
                                            <Col md="8" sm="7">
                                                <span className={(this.state.select_delivery_date_status!=null?"":"d-none")}>
                                                    <DateRangePicker
                                                        singleDatePicker={true}
                                                        date={(this.state.delivery_date==null?moment():this.state.delivery_date)}
                                                        onEvent={this.updateDate}
                                                        autoUpdateInput={true}
                                                        name="delivery_date"
                                                    >
                                                        <div className="btn btn-secondary">
                                                            {(this.state.delivery_date==null?'- Not Selected - ':this.state.delivery_date.format('MMM. D, YYYY'))}
                                                            <span className="fas fa-caret-down fa-sm"></span>
                                                        </div>
                                                    </DateRangePicker>
                                                </span>
                                                <span className={(this.state.select_delivery_date_status==null?"text-bold":"d-none")}>
                                                    {(this.state.delivery_date==null?'- Not Selected - ':this.state.delivery_date.format('MMM. D, YYYY'))}
                                                    <Button size="xs" color="default" className="ml-1 far fa-edit" onClick={this.editDeliveryDate}></Button>
                                                </span>
                                                <span className={((this.state.shipping_status==3 && this.state.delivery_date==null)?"text-danger text-bold ml-2":"d-none")}><i className="fas fa-exclamation-triangle fa-lg"></i> Please Select Delivery Date</span>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Delivery By:</Col>
                                        <Col md="8" sm="7">
                                            <div className={(this.state.select_inventory_source!=null?"d-none":"")}>
                                                <strong>{this.state.inventory_source_name}</strong>
                                                <Button size="xs" color="secondary" className="ml-3 far fa-edit" onClick={this.editInventorySource}></Button>
                                            </div>
                                            <UncontrolledButtonDropdown className={(this.state.select_inventory_source==null?"d-none":"")}>
                                                <DropdownToggle color="secondary" caret size="sm">
                                                    {this.state.dropdown_inventory_source_print}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.inventory_sources).map(function(inventory_source_id, key) {
                                                        return (
                                                            <DropdownItem key={inventory_source_id} onClick={() => this.changeInventorySource(inventory_source_id)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                        )
                                                    },this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md="4" sm="5">Internal Notes:</Col>
                                        <Col md="8" sm="7">
                                            <Input type="textarea" style={{height:'130px'}} onChange={this.noteTyping.bind(this)} value={this.state.notes}></Input>
                                            <Button size="xs" color="secondary" className="mt-1" onClick={this.updateNotes.bind(this)}>Save Notes</Button>
                                        </Col>
                                    </FormGroup>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card className={(this.state.loading?"card card-default whirl traditional":"card card-default")}>
                    <CardBody>
                     <p className="lead bb">Items</p>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.items.map(function(item, key) {
                                    return (
                                    <tr key={key}>
                                        <td>
                                            <a href={'../img/products/'+item.product_id+'.jpg'} className="float-left mr-3 d-none d-sm-block" data-lity>
                                                <img src={'https://order.bevvy.ca/img/products/' + item.product_id + '_sm.png'} style={{height: '40px'}}></img>
                                            </a>
                                        {item.name}
                                        </td>
                                        <td>{item.quantity}</td>
                                    </tr>
                                    )
                                },this)}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <ToastContainer />
            </ContentWrapper>
        );
    }

}

export default BOLDetails;
