import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            title: "",
            reminder: 0,
            status: 0,
            processing: false,
            loading: true
        };
    }

    toggle = () => {
        if (this.state.modal == false) {
            var self = this;
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + '/user/' + this.props.user_id)
                .then(function (response) {
                    console.log(response.data)
                    self.setState(
                        {
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            email: response.data.email,
                            phone: response.data.phone,
                            reminder: response.data.reminder,
                            status: response.data.status,
                            loading: false,
                        })
                    ;
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    saveUser = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        var state = this.state;
        axios.put(API_ROOT + '/user/' + this.props.user_id, state)
            .then(function (response) {
                if (typeof self.props.loadUsers == "function") {
                    self.props.loadUsers();
                }
                self.setState({
                    modal: false,
                    processing: false
                });
                Swal("Success!", response.data.Message, "success");
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <FormGroup>
                <Button color="primary" size="xs" onClick={this.toggle}>{this.props.first_name} {this.props.last_name}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Edit User</div>
                    </ModalHeader>
                    <ModalBody className={(this.state.loading ? "card card-default whirl traditional" : "")}>
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>First Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="first_name" value={this.state.first_name} onChange={(event) => this.updateField("first_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Last Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="last_name" value={this.state.last_name} onChange={(event) => this.updateField("last_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Email*</Label>
                                <Col sm={8}>
                                    <Input type="email" name="email" value={this.state.email} onChange={(event) => this.updateField("email", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Phone</Label>
                                <Col sm={8}>
                                    <Input type="text" name="phone" value={this.state.phone} onChange={(event) => this.updateField("phone", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Reminder Emails</Label>
                                <Col sm={8} className="pl-5">
                                    <Input type="checkbox" name="reminder" value="1" checked={(this.state.reminder == "1" ? true : false)}
                                           onChange={(event) => this.updateField("reminder", !this.state.reminder)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Account Active</Label>
                                <Col sm={8} className="pl-5">
                                    <Input type="checkbox" name="status" value="1" checked={(this.state.status == "1" ? true : false)}
                                           onChange={(event) => this.updateField("status", !this.state.status)}/>
                                </Col>
                            </FormGroup>
                            <div className="border-top pb-2"></div>
                            <FormGroup row>
                                <Label sm={4}>NEW Password <span className="text-muted">(opt.)</span></Label>
                                <Col sm={8}>
                                    <Input type="text" name="password" value={this.state.password} onChange={(event) => this.updateField("password", event)}/>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveUser}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save</Button>
                    </ModalFooter>
                </Modal>
            </FormGroup>
        );
    };
}

export default (EditUser);
