import React, { Component } from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, Form, Alert} from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

class BOLImport extends React.Component {

    constructor(props) {
        super(props);

        window.ImportComponent = this;

        this.state = {

        };
    }

    componentDidMount() {
        document.title = "Import TBS PO | Bevvy";
        var self = this;
        axios.defaults.withCredentials = true;
    }

    saveProduct = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT+'/bol/create/'+this.state.account.account_id, self.state)
            .then(function (response) {
                localStorage.setItem('show_bol_success'+response.data.bol_id, true);
                self.props.history.push("/bol/"+response.data.bol_id);
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        processing: false
                    });
                }
            });
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="/bols/list">BOLs</Link>&nbsp;/ Import TBS Purchase Orders
                </div>
                <div className={(this.state.loading?"card card-default whirl traditional":"card card-default")}>
                    <div className="card-body">
                        <Alert color="info"><i className="fas fa-info-circle"></i> Use this page to upload TBS Purchase Order documents (PDF)</Alert>
                        <Upload />
                        <Button color="primary" onClick={this.saveProduct}>{this.state.mode} Import BOLs</Button>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

const Upload = () => {
    const getUploadParams = ({ file, meta }) => {
        const url = API_ROOT+'/bols/import/';
        const body = new FormData();
        body.append('pdf', file);
        return { url, body  }
    };

    const handleChangeStatus = ({ meta, remove }, status) => {
        console.log('hi')
        if (status === 'headers_received') {
          remove()
        } else if (status === 'aborted') {
          Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={100}
                multiple={true}
                canCancel={false}
                accept="pdf/*"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'PDF files only' : "Upload TBS Purchase Order document(s)")}
                styles={{
                  inputLabel: {fontSize:"14px", fontWeight:"normal"},
                  dropzone: { height: 100, minHeight:75,  border:"1px dashed #ccc", },
                  dropzoneActive: { borderColor: 'green' },
                }}
            />
        </React.Fragment>
    )
};

export default (BOLImport);
