import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export function getAccounts(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);/*
    if (localStorage.getItem('accounts') !== null) {
        var accounts = JSON.parse(localStorage.getItem('accounts'));
        if (accounts.date_fetched>(current_timestamp-(60*60))) {
            fetch_data = false;
            response(accounts.data);
        }
    }*/
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/accounts/`, {withCredentials: true})
            .then(function (ajax_response) {
                var accounts = {};
                accounts.data = {};
                accounts.data = ajax_response.data;
                accounts.date_fetched = current_timestamp;
                localStorage.setItem('accounts', JSON.stringify(accounts));
                response(accounts.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getBanners(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('banners') !== null) {
        var accounts = JSON.parse(localStorage.getItem('banners'));
        if (accounts.date_fetched > (current_timestamp - (60 * 5))) {
            fetch_data = false;
            response(accounts.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/banners/`, {withCredentials: true})
            .then(function (ajax_response) {
                var banners = {};
                banners.data = {};
                banners.data = ajax_response.data;
                banners.date_fetched = current_timestamp;
                localStorage.setItem('banners', JSON.stringify(banners));
                response(accounts.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getDeliveryDate(day, week, mode) {
    if (day == null) {
        if (mode == "display") {
            return "--"
        } else if (mode == "human_date") {
            return "Unknown"
        } else {
            return false
        }
    } else {
        day = day.toLowerCase()
        if (day == "daily") {
            var date = moment().add(2, 'days').endOf('day')
        } else {
            if (day == "sunday") {
                var day_num = 0;
            } else if (day == "monday") {
                var day_num = 1;
            } else if (day == "tuesday") {
                var day_num = 2;
            } else if (day == "wednesday") {
                var day_num = 3;
            } else if (day == "thursday") {
                var day_num = 4;
            } else if (day == "friday") {
                var day_num = 5;
            } else {
                var day_num = 6;
            }
            if (moment().isoWeekday() <= day_num) {
                // then just give me this week's instance of that day
                var date = moment().isoWeekday(day_num);
            } else {
                // otherwise, give me next week's instance of that day
                var date = moment().add(1, 'weeks').isoWeekday(day_num);
            }
        }
        if (mode == "display") {
            var mssg = ""
            if (week != "Weekly") {
                mssg = "1/2 "
            }
            mssg += date.format('ddd')
            return mssg
        } else if (mode == "human_date") {
            return date.format('ddd, MMM D')
        } else {
            return date.format('YYYY-MM-DD')
        }
    }
}

class AccountStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_print: "",
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.status !== this.props.status) {
            this.update_badge()
        }
    }

    update_badge() {
        if (this.props.status == 1) {
            var status_print = "Active";
            var status_css = "success";
        } else if (this.props.status == 0) {
            var status_print = "Closed";
            var status_css = "dark";
        } else if (this.props.status == 2) {
            var status_print = "Lead";
            var status_css = "warning";
        } else {
            var status_print = "???";
            var status_css = "danger";
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css}>{this.state.status_print}</div>
        );
    }
}

export default AccountStatus;