import axios from 'axios';
import {API_ROOT} from '../../api-config';

axios.defaults.withCredentials = true;

export function getProducts(response) { // summary details about all products
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('products') !== null) {
        var product_info = JSON.parse(localStorage.getItem('products'));
        if (product_info.date_fetched > (current_timestamp - (60 * 60 * 1))) {
            fetch_data = false;
            response(product_info.data);
        }
    }
    if (fetch_data) {
        axios.get(`${API_ROOT}/products/`, {withCredentials: true})
            .then(function (ajax_response) {
                var product_info = {};
                product_info.data = {};
                product_info.data = ajax_response.data;
                product_info.date_fetched = current_timestamp;
                localStorage.setItem('products', JSON.stringify(product_info));
                response(product_info.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}

export function getProduct(product_id, response) { // summary details about all products
    axios.get(`${API_ROOT}/product/` + product_id, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getProductsParent(response) {
    axios.get(`${API_ROOT}/products/parent/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getPricingHistory(product_id, response) {
    axios.get(`${API_ROOT}/product/${product_id}/history`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

export function getPricingCategories(response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/pricing_categories`, {withCredentials: true})
        .then(function (ajax_response) {
            console.log('function')
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}
