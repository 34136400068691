import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

import Dashboard from './components/Dashboard/Dashboard';
import AccountPage from './components/Accounts/AccountPage';
import AccountList from './components/Accounts/AccountList';

import CreateBOL from './components/BOL/CreateBOL';
import BOLHistory from './components/BOL/BOLHistory';
import BOLImport from './components/BOL/BOLImport';
import BOLDetails from './components/BOL/BOLDetails'
import OrderHistory from './components/Orders/OrderHistory';
import OrderDetails from './components/Orders/OrderDetails'
import DeliveryList from './components/Delivery/DeliveryList'
import DeliveryCreate from './components/Delivery/DeliveryCreate'
import InventoryOverview from './components/Inventory/InventoryOverview'
import InventoryAdjust from './components/Inventory/InventoryAdjust'
import InventoryTransactions from './components/Inventory/InventoryTransactions'
import ProductsOverview from './components/Products/ProductsOverview'
import ProductOverview from './components/Products/ProductOverview'
import ListsOverview from './components/Admin/ListsOverview'
import MapOrder from './components/Map/MapOrder'
import MapAccount from './components/Map/MapAccount'
import CalendarPage from './components/Calendar/CalendarPage'
import MapSchedule from './components/Map/MapSchedule'
import KegsAdjust from './components/Kegs/KegsAdjust'
import KegTransactions from './components/Kegs/KegTransactions'
import SalesLCBO from './components/Sales/Lcbo'
import SalesNSLC from './components/Sales/Nslc'
import ListUsers from './components/Users/ListUsers'
import ListStaff from './components/Staff/ListStaff'
import PickupList from './components/Pickup/PickupList'
import CreatePickup from './components/Pickup/CreatePickup'
import PickupDetails from './components/Pickup/PickupDetails'
import AccountingList from './components/Accounting/AccountingList'

import Login from './components/Pages/Login';
import NotFound from './components/Pages/NotFound';

import AppliedRoute from "./components/Common/AppliedRoute";


// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/notfound',
    '/error500',
    '/maintenance'
];

const Routes = ({ location,childProps}) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInUp'
    //      'rag-fadeInDown'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    //      'rag-fadeInUpBig'
    //      'rag-fadeInDownBig'
    //      'rag-fadeInRightBig'
    //      'rag-fadeInLeftBig'
    //      'rag-zoomBackDown'
    const animationName = 'rag-fadeIn'

    if(listofPages.indexOf(location.pathname) > -1) {
        if(childProps.isAuthenticated && (location.pathname==="/login" || location.pathname==="/register")) {
            return (
                <Redirect to='/dashboard' />
            )
        } else {
            return (
                    // Page Layout component wrapper
                <BasePage>
                    <Switch location={location}>
                        <AppliedRoute path="/login" component={Login} props={childProps} />
                        <Route path="/notfound" component={NotFound}/>
                    </Switch>
                </BasePage>
            )
        }
    }
    else {
        if(childProps.isAuthenticated===false) {
            return (
                <Redirect to='/login' />
            )
        } else {
            return (
                // Layout component wrapper
                // Use <BaseHorizontal> to change layout
                <Base {...childProps}>
                  <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Switch location={location}>
                                <AppliedRoute path="/dashboard" component={Dashboard} props={childProps}/>
                                <AppliedRoute path="/accounts/map" component={MapAccount} props={childProps}/>
                                <AppliedRoute path="/accounts" component={AccountList} props={childProps}/>
                                <AppliedRoute path="/account/:account_id/call/:call_id" component={AccountPage} props={childProps}/>
                                <AppliedRoute path="/account/:account_id" component={AccountPage} props={childProps}/>
                                <AppliedRoute path="/bol/create/:account_id" component={CreateBOL} props={childProps}/>
                                <AppliedRoute path="/orders/map" component={MapOrder} props={childProps}/>
                                <AppliedRoute path="/orders" component={OrderHistory} props={childProps}/>
                                <AppliedRoute path="/order/:order_id" component={OrderDetails} props={childProps}/>
                                <AppliedRoute path="/bols/list" component={BOLHistory} props={childProps}/>
                                <AppliedRoute path="/bols/import" component={BOLImport} props={childProps}/>
                                <AppliedRoute path="/bol/:bol_id" component={BOLDetails} props={childProps}/>
                                <AppliedRoute path="/delivery/list" component={DeliveryList} props={childProps}/>
                                <AppliedRoute path="/delivery/create" component={DeliveryCreate} props={childProps}/>
                                <AppliedRoute path="/delivery/:delivery_id" component={DeliveryList} props={childProps}/>
                                <AppliedRoute path="/inventory/adjust" component={InventoryAdjust} props={childProps}/>
                                <AppliedRoute path="/inventory/transactions" component={InventoryTransactions} props={childProps}/>
                                <AppliedRoute path="/inventory" component={InventoryOverview} props={childProps}/>
                                <AppliedRoute path="/product/:product_parent_id/:product_id" component={ProductOverview} props={childProps}/>
                                <AppliedRoute path="/product/:product_parent_id" component={ProductOverview} props={childProps}/>
                                <AppliedRoute path="/products" component={ProductsOverview} props={childProps}/>
                                <AppliedRoute path="/lists/:list_name" component={ListsOverview} props={childProps}/>
                                <AppliedRoute path="/lists" component={ListsOverview} props={childProps}/>
                                <AppliedRoute path="/schedule/calendar" component={CalendarPage} props={childProps}/>
                                <AppliedRoute path="/schedule/map" component={MapSchedule} props={childProps}/>
                                <AppliedRoute path="/kegs/return" component={KegsAdjust} props={childProps}/>
                                <AppliedRoute path="/kegs/transactions" component={KegTransactions} props={childProps}/>
                                <AppliedRoute path="/sales/lcbo" component={SalesLCBO} props={childProps}/>
                                <AppliedRoute path="/sales/nslc" component={SalesNSLC} props={childProps}/>
                                <AppliedRoute path="/users" component={ListUsers} props={ListUsers}/>
                                <AppliedRoute path="/staff" component={ListStaff} props={ListStaff}/>
                                <AppliedRoute path="/pickup/create" component={CreatePickup} props={childProps}/>
                                <AppliedRoute path="/pickup/:pickup_id" component={PickupDetails} props={childProps}/>
                                <AppliedRoute path="/pickup" component={PickupList} props={PickupList}/>
                                <AppliedRoute path="/accounting" component={AccountingList} props={childProps}/>
                                <Redirect to="/login"/>
                            </Switch>
                        </div>
                    </CSSTransition>
                  </TransitionGroup>
                </Base>
            )
        }
    }
}

export default withRouter(Routes);
