let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
    backendHost = 'http://localhost:9090';
} else if (hostname === 'umbrel-dev' || hostname === 'staff.umbrel.thewebpeople.ca') {
    backendHost = 'https://staff.umbrel.thewebpeople.ca';
} else {
    backendHost = 'https://staff.bevvy.ca';
}

export const API_ROOT = `${backendHost}/api`;