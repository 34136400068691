import axios from 'axios';
import {API_ROOT} from '../../api-config';

export function getStaff(response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/staff/`, {withCredentials: true})
        .then(function (ajax_response) {
            var staff = {};
            staff.data = {};
            staff.data = ajax_response.data;
            response(staff.data);
        })
        .catch(function (error) {
            response({});
        });
}
