import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Card, CardBody, CardHeader, FormGroup, Button, Input, Modal, ModalHeader, ModalBody} from 'reactstrap';
import MaskedInput from 'react-maskedinput'
import Cards from 'react-credit-cards';
import 'react-credit-cards/lib/styles-compiled.css';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            number: '',
            name: '',
            expiry: '',
            cvc: '',
            focused: '',
            saved_number: '',
            saved_name: '',
            saved_expiry: '',
            saved_issuer: '',
            saved_card_id: '',
            loading: true
        };
        this.addPayment = this.addPayment.bind(this);
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };
    handleInputFocus = ({target}) => {
        this.setState({
            focused: target.name,
        });
    };

    handleInputChange = ({target}) => {
        console.log(target.name)
        console.log(target.value)
        if (target.name === 'number') {
            this.setState({
                [target.name]: target.value.replace(/ /g, ''),
            });
        } else if (target.name === 'expiry') {
            this.setState({
                [target.name]: target.value.replace(/ |\//g, ''),
            });
        } else {
            this.setState({
                [target.name]: target.value,
            });
        }
    };

    addPayment = (props) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/account/' + this.props.account_id + '/card', {number: this.state.number, name: this.state.name, expiry: this.state.expiry, cvc: this.state.cvc})
            .then(function (response) {
                self.setState({
                    number: '',
                    name: '',
                    expiry: '',
                    cvc: '',
                    focused: '',
                });
                document.getElementById("add-card").reset();
                document.getElementById("expiry").value = "";
                self.props.getSavedCC();
                self.setState({
                    modal: false
                });
                Swal("Success!", "Card Added", "success");
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    render() {

        const {name, number, expiry, cvc, focused, saved_number, saved_name, saved_expiry, saved_issuer, saved_card_id} = this.state;
        return (
            <FormGroup className="mb-0">
                <Button color="primary" size="xs" className="mb-1" onClick={this.toggle}><i className="fas fa-plus"></i> Card</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add/Replace Saved Card</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="col-xl-auto" style={{minWidth: '50%'}}>
                            <Cards
                                number={number}
                                name={name}
                                expiry={expiry}
                                focused={focused}
                                cvc={cvc}
                                callback={this.handleCallback}
                            />
                        </div>
                        <div className="col-xl-auto">
                            <form id="add-card">
                                <Input
                                    type="tel"
                                    className="form-control m-1"
                                    name="number"
                                    placeholder="Card Number"
                                    onKeyUp={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                />
                                <input
                                    type="text"
                                    className="form-control m-1"
                                    name="name"
                                    placeholder="Name"
                                    onKeyUp={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                />
                                <MaskedInput
                                    type="tel"
                                    className="form-control m-1"
                                    name="expiry"
                                    placeholder="Expiry (MM/YY)"
                                    onKeyUp={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    mask="11/11"
                                    id="expiry"
                                />
                                <input
                                    type="tel"
                                    name="cvc"
                                    className="form-control m-1"
                                    placeholder="CVC"
                                    onKeyUp={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                />
                            </form>
                        </div>
                        <div className="text-center m-3">
                            <button className="btn btn-primary btn-lg" type="text" onClick={this.addPayment}>Save as Payment Method</button>
                        </div>
                    </ModalBody>
                </Modal>

            </FormGroup>
        );
    }
}

export default (AddCard);
