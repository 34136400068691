import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            staff_id: "",
            first_name: "",
            last_name: "",
            companies: "",
            permissions: "",
            email: "",
            password: "",
            permissions_list: props.permissions,
            companies_list: props.companies,
            status: 1,
            processing: false,
            loading: true
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companies !== this.props.companies) {
            this.setState({
                companies_list: this.props.companies
            });
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    toggleValue = (name, value) => {
        if (name === "permissions") {
            if (value == "ALL") {
                if (this.state.permissions != null && this.state.permissions == 'ALL') {
                    var permissions = '';
                } else {
                    var permissions = 'ALL';
                }
            } else {
                var permissions = ',' + this.state.permissions;
                if (this.state.permissions != null && this.state.permissions.indexOf(',' + value + ',') != -1) {
                    permissions = permissions.replace(',' + value + ',', ',');
                } else {
                    permissions += ',' + value + ',';
                }
            }
            this.setState({
                "permissions": permissions.replace(',,', ',')
            }, this.saveStaff);
        } else if (name === "companies") {
            if (value == "ALL") {
                if (this.state.companies != null && this.state.companies == 'ALL') {
                    var companies = '';
                } else {
                    var companies = 'ALL';
                }
            } else {
                var companies = ',' + this.state.companies;
                if (this.state.companies != null && this.state.companies.indexOf(',' + value + ',') != -1) {
                    companies = companies.replace(',' + value + ',', ',');
                } else {
                    companies += ',' + value + ',';
                }
            }
            this.setState({
                "companies": companies.replace(',,', ',')
            }, this.saveStaff);
        }
    };

    addStaff = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        var state = this.state;
        axios.post(API_ROOT + '/staff', state)
            .then(function (response) {
                self.props.loadStaff();
                self.setState({
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <FormGroup>
                <Button color="primary" onClick={this.toggle}><i className="fas fa-plus"></i> Staff Member</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Staff Member</div>
                    </ModalHeader>
                    <ModalBody className="card card-default">
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>First Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="first_name" value={this.state.first_name} onChange={(event) => this.updateField("first_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Last Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="last_name" value={this.state.last_name} onChange={(event) => this.updateField("last_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Email*</Label>
                                <Col sm={8}>
                                    <Input type="email" name="email" value={this.state.email} onChange={(event) => this.updateField("email", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Open/Active</Label>
                                <Col sm={8} className="pl-5">
                                    <Input type="checkbox" name="status" value="1" checked={(this.state.status == 1 || this.state.status === true ? true : false)}
                                           onChange={(event) => this.updateField("status", (this.state.status == 1 || this.state.status === true ? false : true))}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4} className="pt-0">Companies</Label>
                                <Col sm={8} className="pl-5">
                                    <div>
                                        <Label className="mb-0 text-bold">
                                            <Input type="checkbox" name="status" value="1"
                                                   checked={(this.state.companies != null && this.state.companies.indexOf('ALL') != -1 ? true : false)}
                                                   onChange={(event) => this.toggleValue("companies", "ALL")}/>
                                            - ALL -
                                        </Label>
                                    </div>
                                    {Object.keys(this.state.companies_list).map(function (company_id) {
                                        return (
                                            <div>
                                                <Label className={(this.state.companies == "ALL" ? "mb-0 text-muted" : "mb-0")}>
                                                    <Input type="checkbox" name="status" value="1"
                                                           checked={(this.state.companies != null && (this.state.companies == "ALL" || this.state.companies.indexOf(',' + company_id + ',') != -1) ? true : false)}
                                                           disabled={(this.state.companies == "ALL" ? true : false)}
                                                           onChange={(event) => this.toggleValue("companies", company_id)}/>
                                                    {this.state.companies_list[company_id].name}
                                                </Label>
                                            </div>
                                        )
                                    }, this)}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4} className="pt-0">Permissions</Label>
                                <Col sm={8} className="pl-5">
                                    <div>
                                        <Label className="mb-0 text-bold">
                                            <Input type="checkbox" name="status" value="1"
                                                   checked={(this.state.permissions != null && this.state.permissions.indexOf('ALL') != -1 ? true : false)}
                                                   onChange={(event) => this.toggleValue("permissions", "ALL")}/>
                                            - ALL -
                                        </Label>
                                    </div>
                                    {this.state.permissions_list.map(function (permission) {
                                        return (
                                            <div>
                                                <Label className={(this.state.permissions == "ALL" ? "mb-0 text-muted" : "mb-0")}>
                                                    <Input type="checkbox" name="status" value="1"
                                                           checked={(this.state.permissions != null && (this.state.permissions == "ALL" || this.state.permissions.indexOf(',' + permission.code + ',') != -1) ? true : false)}
                                                           disabled={(this.state.permissions == "ALL" ? true : false)}
                                                           onChange={(event) => this.toggleValue("permissions", permission.code)}/>
                                                    {permission.name}
                                                </Label>
                                            </div>
                                        )
                                    }, this)}
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addStaff}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Add Staff</Button>
                    </ModalFooter>
                </Modal>
            </FormGroup>
        );
    };
}

export default (AddStaff);
