import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            existing_email: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            send_email: "No",
            title: "",
            account_name: "",
            processing: false
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    addUser = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        var state = this.state;
        state['account_name'] = this.props.account_name
        axios.post(API_ROOT + '/account/' + this.props.account_id + '/user', state)
            .then(function (response) {
                self.props.loadUsers();
                self.setState({
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        var options = ["Yes", "No"]
        return (
            <FormGroup>
                <Button color="primary" size="xs" onClick={this.toggle}><i className="fas fa-plus"></i> User</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add User</div>
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>Job Title/Role</Label>
                                <Col sm={8}>
                                    <Input type="text" name="title" value={this.state.title} onChange={(event) => this.updateField("title", event)}/>
                                </Col>
                            </FormGroup>
                            <div className="border-top"></div>
                            <h4>Link Existing User</h4>
                            <FormGroup row>
                                <Label sm={4}>Existing Email</Label>
                                <Col sm={8}>
                                    <Input type="text" name="existing_email" value={this.state.existing_email} onChange={(event) => this.updateField("existing_email", event)}/>
                                </Col>
                            </FormGroup>
                            <div className="border-top"></div>
                            <h4>Or Create New User</h4>
                            <FormGroup row>
                                <Label sm={4}>First Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="first_name" value={this.state.first_name} onChange={(event) => this.updateField("first_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Last Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="last_name" value={this.state.last_name} onChange={(event) => this.updateField("last_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Email*</Label>
                                <Col sm={8}>
                                    <Input type="email" name="email" value={this.state.email} onChange={(event) => this.updateField("email", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Password*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="password" value={this.state.password} onChange={(event) => this.updateField("password", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Phone</Label>
                                <Col sm={8}>
                                    <Input type="text" name="phone" value={this.state.phone} onChange={(event) => this.updateField("phone", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Send Welcome Email</Label>
                                <Col sm={8}>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle size="sm" color="secondary" caret>
                                            {this.state.send_email}
                                        </DropdownToggle>
                                        <DropdownMenu style={{"zIndex": 999}}>
                                            {Object.keys(options).map(function (id, key) {
                                                return (
                                                    <DropdownItem key={id} onClick={() => this.updateField("send_email", options[id])}>{options[id]}</DropdownItem>
                                                )
                                            }, this)}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addUser}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Add User</Button>
                    </ModalFooter>
                </Modal>
            </FormGroup>
        );
    };
}

export default (AddUser);
