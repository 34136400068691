import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, UncontrolledButtonDropdown, InputGroup, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'
import {getStaff} from '../Staff/StaffFunctions.js';
import moment from 'moment';
import 'moment-timezone';
const {
    Marker,
    InfoWindow,
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} = require("react-google-maps");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

moment.tz.setDefault("America/Toronto");

class MapAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accounts:[],
            pings: [],
            staff: [],
            loading: true,
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : 99),
            account_status: (localStorage.getItem('account_status') != null ? localStorage.getItem('account_status') : 1),
            account_rep: (localStorage.getItem('account_rep') != null ? localStorage.getItem('account_rep') : 0),
            account_status_name: (localStorage.getItem('account_status_name') != null ? localStorage.getItem('account_status_name') : "Active"),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "Retail & OP"),
            account_rep_name: (localStorage.getItem('account_rep_name') != null ? localStorage.getItem('account_rep_name') : "All"),
            account_layout: (localStorage.getItem('account_layout') != null ? localStorage.getItem('account_layout') : null),
            account_types: {
                0: "Special",
                1: "On-Premise",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                10: "Duty-Free",
                11: "Convenience",
                98: "All Retail",
                99: "All Retail & OP"
            },
            account_statuses: {0: "Closed", 1: "Active"},
            layouts: {"EXP": "EXP", "PSS": "PSS", "CONV": "CONV", "DC": "DC", "FRS": "FRS", "BOUTIQUE": "BOUTIQUE", "PROT EXP": "PROT EXP", "CONV/DC": "CONV/DC"},
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            call_frequency_options: {
                0: "Non-Visited",
                7: "Weekly (Tier 1)",
                14: "Bi-Weekly (Tier 2)",
                30: "Monthly (Tier 3)",
                90: "Quarterly (Tier 4)",
            },
            mode: "Rep",
            modes: ["Rep", "Volume"]
        };
    }

    componentDidMount() {
        document.title = "Account Map | Bevvy";
        var self = this;
        getStaff(function(staff) {
            staff[0] = {
                 'first_name': 'All',
                 'last_name': ''
             };
             staff['0'] = {
                'first_name': '- Unassigned -',
                'last_name': ''
            };
             self.setState({
                 staff: staff,
             });
             self.getAccounts();
        });
    }

    getAccounts(){
        this.setState({
            loading:true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/accounts/summary/?account_realfake=1&account_type=' + self.state.account_type + '&account_status=' + self.state.account_status + "&sales_rep_id=" + self.state.account_rep + "&account_layout=" + self.state.account_layout + "&mode=" + self.state.mode)
            .then(function (response) {
                self.setState({
                    accounts: response.data,
                    loading:false,
                });

            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        accounts:[],
                        loading:false
                    });
                }
            });
        axios.get(API_ROOT+"/pings/summary")
            .then(function (response) {
                self.setState({
                    pings:response.data,
                });
            })
            .catch(function (error) {
                if (error.response.status===401){
                    self.props.userSignOut()
                } else {
                    //
                }
            });
    }

    changeAccountType = (account_type) => {
        if (account_type==null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type', account_type);
        localStorage.setItem('account_type_name', account_type_name);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name
        }, () => {
            this.getAccounts()
        });
    };

    changeAccountStatus = (account_status) => {
        if (account_status==null) {
            var account_status_name = "All"
        } else {
            var account_status_name = this.state.account_statuses[account_status]
        }
        localStorage.setItem('account_status', account_status);
        localStorage.setItem('account_status_name', account_status_name);
        this.setState({
            account_status: account_status,
            account_status_name: account_status_name
        }, () => {
            this.getAccounts()
        });
    };

    changeRep = (sales_rep_id) => {
        var account_rep_name = this.state.staff[sales_rep_id].first_name+" "+this.state.staff[sales_rep_id].last_name;
        localStorage.setItem('account_rep', sales_rep_id);
        localStorage.setItem('account_rep_name', account_rep_name);
        this.setState({
            account_rep: sales_rep_id,
            account_rep_name: account_rep_name
        }, () => {
            this.getAccounts()
        });
    };

    changeLayout = (account_layout) => {
        var account_layout_name = "All"
        if (account_layout!=null){
            account_layout_name = account_layout;
        }
        localStorage.setItem('account_layout', account_layout);
        localStorage.setItem('account_layout_name', account_layout_name);
        this.setState({
            account_layout: account_layout,
            account_layout_name: account_layout_name
        }, () => {
            this.getAccounts()
        });
    };

    changeMode = (mode) => {
        this.setState({
            mode: mode,
        }, () => {
            this.getAccounts()
        });
    };

    goToAccount= (account_id) => {
        this.props.history.push("/account/"+account_id);
    };

    refreshSearch = event => {
        this.getAccounts();
    };


    // map specific
    onMarkerClick = (props, marker) =>
        this.setState({
          activeMarker: marker,
          selectedPlace: props,
          showingInfoWindow: true
        });

      onInfoWindowClose = () =>
        this.setState({
          activeMarker: null,
          showingInfoWindow: false
        });

      onMapClicked = () => {
        if (this.state.showingInfoWindow)
          this.setState({
            activeMarker: null,
            showingInfoWindow: false
          });
      };

    render() {

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Account Map</div>
                    <div className="ml-auto">
                        <Button onClick={this.refreshSearch}><i className="fas fa-sync-alt"></i></Button>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex:1000}}>
                    <InputGroup className="d-none d-sm-block">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="primary" style={{fontSize:'13px',width:'auto'}}>
                                Status: <b>{this.state.account_status_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeAccountStatus(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.account_statuses).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeAccountStatus(key)}>{this.state.account_statuses[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup className="d-none d-sm-block">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="primary" caret style={{fontSize:'13px',width:'auto'}}>
                                Account Type: <b>{this.state.account_type_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.account_types).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup direction="down">
                        <UncontrolledButtonDropdown direction="down">
                            <DropdownToggle direction="down" color="primary" caret style={{fontSize:'13px',width:'auto'}}>
                                Rep: <b>{this.state.account_rep_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                {Object.keys(this.state.staff).map((sales_rep_id, i) => {
                                    if (this.state.staff[sales_rep_id].status == 1) {
                                        return (<DropdownItem key={sales_rep_id}
                                                              onClick={() => this.changeRep(sales_rep_id)}>{this.state.staff[sales_rep_id].first_name} {this.state.staff[sales_rep_id].last_name}</DropdownItem>)
                                    }
                                }, this)}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color={(String(this.state.account_layout)=="null"?"primary":"warning")} caret style={{fontSize:'13px',width:'auto'}}>
                                <b>Layout: {this.state.account_layout_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeLayout(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.layouts).map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeLayout(key)}>{this.state.layouts[key]}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color={(String(this.state.account_layout) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                                <b>Mode: {this.state.mode}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                {this.state.modes.map((key, i) =>
                                    <DropdownItem key={key} onClick={() => this.changeMode(key)}>{key}</DropdownItem>
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                </div>
                <div className={(this.state.loading?"card card-default whirl traditional":"card card-default")} style={{"height":'100vh'}}>
                    <div style={{ height: '100vh', width: '100%' }}>
                        <MapWithAMakredInfoWindow3
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAeOjO5jj-fhGU9So1rtJESjbuTh-6gJkg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            markers={this.state.accounts}
                            pings={this.state.pings}
                            staff={this.state.staff}
                            goToAccount={this.goToAccount}
                            account_rep={this.state.account_rep}
                            call_frequency_options={this.state.call_frequency_options}
                            mode={this.state.mode}
                        />
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export class Map3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened_marker:false
        };
    }

    onToggleOpen = function(account_id){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            window.scrollTo(0, 200);
        }
        if (this.state.opened_marker==account_id){
            account_id = false;
        }
        this.setState({
            opened_marker: account_id
        });
    };

    render() {
        return (
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{lat: 43.651890, lng: -79.381706}}
                options={{ fullscreenControl: true, gestureHandling: 'greedy' }}
            >
                {this.props.markers.map(function (marker) {
                    var items = "";
                    for (var num in marker['items']){
                        var item = marker['items'][num];
                        items += "<div> &bull; "+item.quantity+"x "+item.name+"</div>";
                    }
                    var unknown_location = false;
                    if (isNaN(marker.lat)){
                        marker.lat = "43.802620";
                        marker.lng = "-79.619247";
                        unknown_location = true;
                    }
                    if (marker.sales_rep_id==null){
                        marker.sales_rep_id = 0;
                    }
                    var marker_url = "../img/markers/spotlight-poi2_hdpi_"+marker.sales_rep_id+".png";
                    if (this.props.account_rep>0){
                        if (marker.account_type==2){
                            marker_url = "https://staff.bevvy.ca/img/markers/tbs2x.png"
                        } else if (marker.account_type==3){
                            marker_url = "https://staff.bevvy.ca/img/markers/lcbo2x.png"
                        } else if (marker.account_type==5){
                            marker_url = "https://staff.bevvy.ca/img/markers/grocery2x.png"
                        }
                    }
                    if (this.props.mode == "Volume" && typeof marker.hl != "undefined") {
                        if (marker.hl == 0) {
                            marker_url = "../img/markers/spotlight-poi2_hdpi_0.png";
                        } else if (marker.hl < 5) {
                            marker_url = "../img/markers/spotlight-poi2_hdpi_1.png";
                        } else if (marker.hl < 15) {
                            marker_url = "../img/markers/spotlight-poi2_hdpi_9.png";
                        } else {
                            marker_url = "../img/markers/spotlight-poi2_hdpi_5.png";
                        }
                    }
                    var directions_url = "https://www.google.com/maps/dir/?api=1&destination="+marker.address1+", "+marker.city+", Ontario&layer=t";
                    return (
                        <Marker
                            position={{lat: marker.lat, lng: marker.lng}}
                            key={marker.account_id}
                            onClick={this.onToggleOpen.bind(this,marker.account_id)}
                            icon= {{
                                url: marker_url,
                                anchor: new window.google.maps.Point(15,32),
                                scaledSize: new window.google.maps.Size(27,43)
                               }}
                        >
                            {this.state.opened_marker==marker.account_id && <InfoWindow key={marker.account_id}>
                                <div style={{fontSize:'14px'}}>
                                    <div className="h4 mb-1">{marker.name}</div>
                                    <div className={(unknown_location?"text-danger h4 mb-o":"d-none")}>(Map Location Unknown)</div>
                                    <div>{marker.address1}{(marker.address2!=null?" "+marker.address2:"")}, {marker.city}</div>
                                    <div className={(marker.phone==null || marker.phone.length<=3?"d-none":"")}><small>TEL:</small> <a href={'tel:'+marker.phone}>{marker.phone}</a></div>
                                    <div><small>ADDED:</small> {marker.date_opened}</div>
                                    <div><small>CLASS:</small> {this.props.call_frequency_options[marker.call_frequency]}</div>
                                    <div><small>SALES REP:</small> {(marker.sales_rep_id>0?this.props.staff[marker.sales_rep_id].first_name+" "+this.props.staff[marker.sales_rep_id].last_name:"<Not Assigned>")}</div>
                                    <div><small>LAST VISIT:</small> {(marker.last_visited==null || isNaN(marker.last_visited)?"Never":moment().diff(moment(marker.last_visited, "MMM. D, YYYY"), "days")+" Days Ago")}</div>
                                    <div><small>SALES:</small> {(marker.total_spent != null ? "$" + marker.total_spent : "$0")} <span
                                        className={(marker.owing > 0 ? "text-warning" : "d-none")}>(${marker.owing} Owing)</span></div>
                                    <div className="pb-1">
                                        <small>INVENTORY:</small>&nbsp;
                                        <span
                                            className={(marker.f75_inv < 1 || marker.f75_inv == null ? "badge badge-danger" : (marker.f75_inv < 7 ? "badge badge-warning" : "badge badge-success"))}>F75: {(marker.f75_inv != null ? marker.f75_inv : "0")}</span>&nbsp;
                                        <span
                                            className={(marker.st_inv < 1 || marker.st_inv == null ? "badge badge-danger" : (marker.st_inv < 7 ? "badge badge-warning" : "badge badge-success"))}>ST: {(marker.st_inv != null ? marker.st_inv : "0")}</span>&nbsp;
                                        <span
                                            className={(marker.aq_inv < 1 || marker.aq_inv == null ? "badge badge-danger" : (marker.aq_inv < 7 ? "badge badge-warning" : "badge badge-success"))}>AL: {(marker.aq_inv != null ? marker.aq_inv : "0")}</span>&nbsp;
                                        <span
                                            className={(marker.sbll_inv < 1 || marker.sbll_inv == null ? "badge badge-danger" : (marker.sbll_inv < 7 ? "badge badge-warning" : "badge badge-success"))}>SB LL: {(marker.sbll_inv != null ? marker.sbll_inv : "0")}</span>&nbsp;
                                        <span
                                            className={(marker.sbbb_inv < 1 || marker.sbbb_inv == null ? "badge badge-danger" : (marker.sbbb_inv < 7 ? "badge badge-warning" : "badge badge-success"))}>SB BB: {(marker.sbbb_inv != null ? marker.sbbb_inv : "0")}</span>
                                        <span
                                            className={(marker.sbpgf_inv < 1 || marker.sbpgf_inv == null ? "badge badge-danger" : (marker.sbpgf_inv < 7 ? "badge badge-warning" : "badge badge-success"))}>SB Pink: {(marker.sbpgf_inv != null ? marker.sbpgf_inv : "0")}</span>
                                    </div>
                                    <div className={((marker.notes != null && marker.notes != "") ? "text-danger mb-2" : "d-none")}>
                                        <small>NOTE:</small> {marker.notes}
                                    </div>
                                    <div>
                                        <div className="btn btn-xs btn-primary" onClick={this.props.goToAccount.bind(this, marker.account_id)}>ST {marker.account_id}</div>
                                        <a href={directions_url} target="_blank" className="btn btn-xs btn-info float-right"><i className="fa fa-location-arrow"></i> Directions</a>
                                    </div>
                                </div>
                            </InfoWindow>}
                        </Marker>
                    )
                }, this)}
                {this.props.pings.map(function (marker) {
                    var marker_url = "../img/markers/spotlight-x`" + marker.staff_id + ".png";
                    var unknown_location = false;
                    if (isNaN(marker.latitude)){
                        marker.latitude = "43.802620";
                        marker.longitude = "-79.619247";
                        unknown_location = true;
                    }
                    if (this.props.inventory_source_id==null || this.props.inventory_source_id==marker.staff_id){
                        return (
                            <Marker
                                position={{lat: marker.latitude, lng: marker.longitude}}
                                key={marker.inventory_source_id}
                                onClick={this.onToggleOpen.bind(this,marker.inventory_source_id)}
                                icon= {{
                                    url: marker_url,
                                    origin: new window.google.maps.Point(0,0),
                                    anchor: new window.google.maps.Point(14,43),
                                    scaledSize: new window.google.maps.Size(27,43)
                                   }}
                                animation={window.google.maps.Animation.BOUNCE}
                            >
                                {this.state.opened_marker==marker.inventory_source_id && <InfoWindow key={marker.inventory_source_id}>
                                    <div style={{fontSize:'14px'}}>
                                        <div className={(unknown_location?"text-danger h4 mb-o":"d-none")}>(Unknown Location)</div>
                                        <div className="h4 mb-1">{this.props.inventory_sources[marker.inventory_source_id].name} Location</div>
                                        As of {marker.last_time}<br/>
                                        Accuracy: {marker.accuracy}m<br/>
                                        Speed: {marker.speed}
                                        {this.props.account_rep}
                                    </div>
                                </InfoWindow>}
                            </Marker>
                        )
                    }
                }, this)}
            </GoogleMap>
        );
    }
}
export const MapWithAMakredInfoWindow3 = withScriptjs(withGoogleMap(Map3));

export default (MapAccount);
